import {
  // AFFILIATE_DEALER_DATA,
  // AFFILIATE_MASTER_DATA,
  CREATE_AFFELIATE_STATUS,
  // IS_DEALER_EDIT,
  // IS_MASTER_EDIT,
  SELECTED_RADIO_BTN,
  SET_ACTIVE_TAB,
} from "../types/affiliate";

interface AffiliateMasterID {
  // masterIdData: String;
  createAffiliateStatus: boolean;
  selectedRadioBtn: any;
  // masterEdit: any;
  // dealerEdit: any;
  // dealerIdData: any;
  // allSuperMasters: any;
  activeTabState: any;
}

const initialState: AffiliateMasterID = {
  // masterIdData: "",
  createAffiliateStatus: false,
  selectedRadioBtn: "1",
  // masterEdit: false,
  // dealerEdit: false,
  // dealerIdData: "",
  // allSuperMasters: [],
  activeTabState: "1",
};

const AffiliateUsersData = (state = initialState, action: any) => {
  switch (action.type) {
    // case AFFILIATE_MASTER_DATA: {
    //   return {
    //     ...state,
    //     masterIdData: action.payload,
    //   };
    // }
    case CREATE_AFFELIATE_STATUS: {
      return {
        ...state,
        createAffiliateStatus: action.payload,
      };
    }
    case SELECTED_RADIO_BTN: {
      return {
        ...state,
        selectedRadioBtn: action.payload,
      };
    }
    case SET_ACTIVE_TAB: {
      return {
        ...state,
        activeTabState: action.payload,
      };
    }
    // case IS_MASTER_EDIT: {
    //   return {
    //     ...state,
    //     masterEdit: action.payload,
    //   };
    // }
    // case IS_DEALER_EDIT: {
    //   return {
    //     ...state,
    //     dealerEdit: action.payload,
    //   };
    // }
    // case AFFILIATE_DEALER_DATA: {
    //   return {
    //     ...state,
    //     dealerIdData: action.payload,
    //   };
    // }
    default:
      return state;
  }
};
export default AffiliateUsersData;
