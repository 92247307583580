import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import AuthenticatedRoute from "../authentication/AuthenticatedRoute";
import { Spin } from "antd";
// import { useTypedSelector } from "../../hooks/useTypeSelector";

const ResetPassword = React.lazy(() => import("../../pages/ResetPassword"));
const ForgotMpin = React.lazy(() => import("../../pages/forgotMpin"));
const LogIn = React.lazy(() => import("../../pages/LogIn"));
const ForgotPassword = React.lazy(() => import("../../pages/ForgotPassword"));
const MainLayout = React.lazy(() => import("../../layout/MainLayout"));

const AuthPage = () => {
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const { isUserAuthenticated } = useTypedSelector((state) => state.auth);
  // useEffect(() => {
  //   if (isUserAuthenticated) {
  //     setIsAuthenticated(true);
  //   }
  // }, [isUserAuthenticated]);
  return (
    // <AuthContext.Provider value={isAuthenticated}>
    <BrowserRouter basename="">
      <Switch>
        <AuthenticatedRoute
          exact
          path={"/"}
          onAuthenticated={() => {
            return <Redirect to={"/dashboard"} />;
          }}
          onUnauthenticated={() => {
            return (
              <Suspense
                fallback={
                  <Spin className="w-full h-[100vh] flex items-center justify-center" />
                }
              >
                <LogIn />
              </Suspense>
            );
          }}
        />
        <AuthenticatedRoute
          exact
          path={"/login"}
          onAuthenticated={() => {
            return <Redirect to={"/dashboard"} />;
          }}
          onUnauthenticated={() => {
            return (
              <Suspense
                fallback={
                  <Spin className="w-full h-[100vh] flex items-center justify-center" />
                }
              >
                <LogIn />
              </Suspense>
            );
          }}
        />
        <AuthenticatedRoute
          exact
          path={"/forgot-password"}
          onAuthenticated={() => {
            return <Redirect to={"/dashboard"} />;
          }}
          onUnauthenticated={() => {
            return (
              <Suspense
                fallback={
                  <Spin className="w-full h-[100vh] flex items-center justify-center" />
                }
              >
                <ForgotPassword />
              </Suspense>
            );
          }}
        />
        <AuthenticatedRoute
          exact
          path={"/forgot-mpin/:token"}
          onAuthenticated={() => {
            return <Redirect to={"/dashboard"} />;
          }}
          onUnauthenticated={() => {
            return (
              <Suspense
                fallback={
                  <Spin className="w-full h-[100vh] flex items-center justify-center" />
                }
              >
                <ForgotMpin />
              </Suspense>
            );
          }}
        />
        <AuthenticatedRoute
          exact
          path={"/reset-password/:token"}
          onAuthenticated={() => {
            return <Redirect to={"/dashboard"} />;
          }}
          onUnauthenticated={() => {
            return (
              <Suspense
                fallback={
                  <Spin className="w-full h-[100vh] flex items-center justify-center" />
                }
              >
                <ResetPassword />;
              </Suspense>
            );
          }}
        />
        <AuthenticatedRoute
          exact
          path={"/dashboard"}
          onAuthenticated={() => {
            return (
              <Suspense
                fallback={
                  <Spin className="w-full h-[100vh] flex items-center justify-center" />
                }
              >
                <MainLayout />
              </Suspense>
            );
          }}
          onUnauthenticated={() => {
            return <Redirect to={"/"} />;
          }}
        />
        <AuthenticatedRoute
          onAuthenticated={() => {
            return (
              <Suspense
                fallback={
                  <Spin className="w-full h-[100vh] flex items-center justify-center" />
                }
              >
                <MainLayout />
              </Suspense>
            );
          }}
          onUnauthenticated={() => {
            return (
              <Suspense
                fallback={
                  <Spin className="w-full h-[100vh] flex items-center justify-center" />
                }
              >
                <LogIn />
              </Suspense>
            );
          }}
        />
      </Switch>
    </BrowserRouter>
    // </AuthContext.Provider>
  );
};
export default AuthPage;
