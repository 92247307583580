import { message } from "antd";
import CryptoJS from "crypto-js";
const STORAGE_KEY = "redux_state";
export const saveToLocalStorage = (state: any) => {
  try {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(state),STORAGE_KEY).toString();
    localStorage.setItem("state", encryptedData);
  } catch (e: any) {
    message.error(e);
  }
};


export const loadFromLocalStorage = () => {
  try {
    const stateStr = localStorage.getItem("state");
    if(!stateStr){
      return
    }
    
    const bytes = CryptoJS.AES.decrypt(stateStr,STORAGE_KEY);
    
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    return decryptedData;
  } catch (e) {
    return undefined;
  }
};
