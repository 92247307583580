import "bootstrap/dist/css/bootstrap.min.css";
import "./app.scss";
import "./styles/styles.scss";
import AuthPage from "./components/landingRoute/AuthPage";

function App() {

  return (
    <>
      <AuthPage />
    </>
  );
}

export default App;
